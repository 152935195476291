<script>
export default {
    name:'FooterCompoment',
    data(){
        return{
            listPropriete:[]
        }
    },
    created() {
        //  this.$store.dispatch('getAllListOfPropriete')
    }
}
</script>
<template>
    <footer class="dark-footer skin-dark-footer text-start" v-if="this.$store.state.allBienImmobilier">
        <div>
            <div class="container">
                <div class="row">
                    
                    <div class="col-lg-4 col-md-4">
                        <div class="footer-widget ">
                            <img src="/dexter_logo1.jpg" class="img-footer" alt="img" />
                            <div class="footer-add text-start text-light">
                                <p>Riviera nouveau goudron cité sir villa 59</p>
                                <p>+225 05 74 14 30 30</p>
                                <p>https://dexter-immo.com/</p>
                            </div>
                            
                        </div>
                    </div>		
                    <div class="col-lg-4 col-md-4">
                        <div class="footer-widget text-light">
                            <h4 class="widget-title text-warning">Liens</h4>
                            <ul class="footer-menu text-light">
                                <li><router-link class="text-light" to="/">Accueil</router-link></li>
                                <li><router-link class="text-light" to="/terrains_a_vendre">Terrains et maisons</router-link></li>
                                <li><router-link class="text-light" to="/Residence_en_location">Résidences meublées</router-link></li>
                                <li><router-link class="text-light" to="/about">A propos de nous</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="footer-widget">
                            <h4 class="widget-title text-warning">Réseaux sociaux</h4>
                            <ul class="footer-menu d-flex justify-content-start align-items-center">
                                <li><a href="#"><i class="bi bi-facebook text-light h5 mx-3"></i></a></li>
                                <li><a href="#"><i class="bi bi-instagram text-light h5"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="footer-bottom ">
            <div class="container">
                <div class="row align-items-center">
                    
                    <div class="col-lg-6 col-md-6 text-light">
                        <p class="mb-0">© 2024 Dexter</p>
                    </div>

                </div>
            </div>
        </div>
    </footer>
</template>
<style scoped>
.footer-bottom{
    background-color:black !important;
}
</style>
